.main{
    height: 100vh;
    z-index: 0;
}

.mainContainer{
    background-color: white;
}
.headerContainer{
    position: fixed;
    z-index: 100;
    width: 100vw;
}
