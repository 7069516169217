$sidebar-width: 300px;

div.sidebarContainerClosed{
    position: absolute;
    height: 100vh;
    // right:100%;
    // margin-top: 64px;
    padding: 30px ;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: rgb(0, 0, 0);
    color: white;
    width: $sidebar-width;
    z-index: 101;
    transform: translateX(-100%);
    transition: all 0.6s cubic-bezier(0.645,0.045,0.355,1);
    @for $i from 0 through 3 {
    
    .slideLeft-#{$i}{
            content:"";
            opacity: 1;
            font-family: "Trispace";
            transform: translateY(0px);
            // animation: fallDown 0.6s cubic-bezier(0.3,0.045,0.355,1);
            // animation-delay: #{$i}s;
            animation: slideLeft cubic-bezier(0.3,0.045,0.355,1) 0.5s infinite;
            // transform: translateY(0px);
            animation-delay: #{$i/20}s ;

        
    }
    
}
}

div.sidebarContainerOpen{
    position: absolute;
    height: 100vh;
    // margin-top: 64px;
    padding: 30px ;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: rgb(0, 0, 0);
    color: white;
    width: $sidebar-width;
    z-index: 101;
    transform: translateX(0%);
    transition: all 0.5s cubic-bezier(0.645,0.045,0.355,1);
    
@for $i from 0 through 3 {
    
    .slideRight-#{$i}{
            content:"";
            opacity: 1;
            font-family: "Trispace";
            transform: translateY(-80px);
            // animation: fallDown 0.6s cubic-bezier(0.3,0.045,0.355,1);
            // animation-delay: #{$i}s;
            animation: slideRight cubic-bezier(0.3,0.045,0.355,1) 0.5s forwards;
            // transform: translateY(0px);
            animation-delay: #{$i/35}s ;

        
    }
    
}
    
}





@keyframes slideRight{
    0%{
        opacity: 0;
        transform: translateX(-80%);
        
    }
    100%{
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes slideLeft{
    0%{
        opacity: 1;
        transform: translateX(0px);
        
    }
    100%{
        opacity: 0;
        transform: translateX(-200px);
    }
}

@media screen and (min-width: 900px) {
    div.sidebarContainerClosed{
        display: none;
    }
    div.sidebarContainerOpen{
        display: none;
    }
}