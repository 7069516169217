html,
body {
    margin: 0;
    padding: 0;
    background-color: black;
}

/* ::-webkit-scrollbar {display:none;} */


h5.sectionTitleStyle {
    font-weight: 700;
    font-family: "Trispace";
}


.sectionTitle-VisibleL {
    opacity: 0;
    transform: translateX(0px);
    animation: slideInFromLeft 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
    /* animation-delay: 0.01s; */
}

.sectionDividerAnimation {
    /* opacity: 0; */
    width: 0px;
    height: 2px;
    background-color: black;
    animation: dividerWidthAnimation 0.5s ease-in-out forwards;
    animation-delay: 0.01s;
}


.sectionText-Visible {
    opacity: 0;
    transform: translateY(0px);
    animation: slideInFromTop 0.5s ease-in-out forwards;
    animation-delay: 0.01s;
}

.hidden-default {
    opacity: 0;
    display: none;
}


@keyframes dividerWidthAnimation {
    0% {
        width: 0px;
        opacity: 0;
    }

    100% {
        opacity: 1;
        width: 2.4rem;
    }
}


@keyframes slideInFromTop {
    0% {
        opacity: 0;
        transform: translateY(-10%);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-3%);
        opacity: 0;
    }

    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(10%);
        opacity: 0;
    }

    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@media screen and (max-width: 768px) {
    h5.sectionTitleStyle {
        font-family: "Trispace";
        font-weight: 700;
        font-size: 1.28rem;
    }

    @keyframes dividerWidthAnimation {
        0% {
            width: 0px;
            opacity: 0;
        }

        100% {
            opacity: 1;
            width: 2rem;
        }
    }
}