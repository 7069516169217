div.cardAnimation{
    opacity: 0;
    animation: cardAnimation 0.4s cubic-bezier(0.645,0.045,0.355,1) forwards;
}

@keyframes cardAnimation{
    0%{
        opacity: 0;
        transform: translateY(-20px);
    }

    100%{
        opacity: 1;
        transform: translateY(0px);
    }
}
    