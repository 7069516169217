div.footer-container{
    // position:sticky;
    // bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 100%;
    background-color: black;
    color: white;
    font-family: "Trispace";
    // margin-left: 0;
}

p.footer-title{
    font-family: inherit;
    font-size: 0.7rem;
}